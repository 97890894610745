.cards {

    text-align: center;

    img {
        height: 55px;
        margin:2px;
    }
}
.card-body {
    padding:25px;
}