.search-results {
    position: relative !important;
    width: 100% !important;
}
.search-results-container {
    background-color: $gray-900;
    color: #fff;
    width: 100% !important;
    z-index: 1001;
    margin-top: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    //border-bottom: 2px solid $primary;
    //border-top: 2px solid $primary;
    .search-result-item {
        padding: 6px 12px;
        cursor: pointer;
        display: block;
        overflow: hidden;

        .thumb-picture {
            width: 50px;
            float: left;
            display: inline-block;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .info-box {
            width: calc(100% - 70px);
            color: #fff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            float: left;
            display: block;
            padding: 5px;
            padding-left: 15px;
            font-weight: bold;
        }

        a {
            color: #dcdcdc;
        }

        em {
            font-weight: bold;
            font-style: normal;
            color: $green;
        }
    }

    .search-result-item:hover {
        background-color: $gray-800;
    }

    .search-results-title {
        border-bottom: 1px solid rgba(243, 243, 243, 0.6);
        padding: 6px 12px;
        font-weight: bold;
    }
    .search-empty {
        padding: 6px 12px;    
    }

    .search-results-products {
        .search-result-item {
            min-height: 60px;
            width: 50%;
            float: left;
        }
    }

    .search-results-categories {
        .search-result-item {
            em {
                color: $blue !important;
            }
        }
    }

    .search-results-articles {
        .search-result-item {
            em {
                color: $red !important;
            }
        }
    }

}

@media (max-width: 767.98px) {
    .search-results-container {
        overflow-y: scroll;
        max-height: calc(100vh - 175px);
        .search-results-products {
            .search-result-item {
                width: 100%;
            }
        }
    }
}


