/*****************************
Pages - Privacy Policy
*****************************/

.privacy-policy-wrapper {
    margin-bottom: -36px;
}
.privacy-single-item {
    margin-bottom: 29px;
    h3{
        margin-bottom: 15px;
    }
    h4 {
        font-size: 22px;
    }
}

