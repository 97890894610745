.breadcrumb-item + .breadcrumb-item::before {
  content: none !important;
}
.breadcrumb-item + .breadcrumb-item{
  padding-left: 0;
}
ul.pagination {
  justify-content: center !important;
  display: flex !important;
}

.list-group {


}



.btn-wishlist {
  padding: 0;
  font-size: 20px;
  color: $secondary;
}

.add-to-cart-container {
    @media #{$large-mobile} {
        width: 100%;
    }
}

.btn-success {
  color: #fff;
}

.form-group {
    label {
        margin-bottom: 5px;
    }
    margin-bottom: 15px;
}

.form-row {
	margin-bottom: 15px;
}

ul.flag-list li a {
    opacity: 0.8;
}

ul.flag-list li a.active {
    opacity: 1;
}

.owl-nav.disabled {
  display: block !important;
}


.card-body {
    padding:25px;
}
.form-select {
  background-image: none !important;

}

.form-check {
  .form-check-input {
    margin-top:5px;

  }
}
.cart_subtotal {
  p {
    span {
      margin-right:0;
    }
  }

}
.coupon_code {
  .cart_amount {
    .price-eur {
      font-size:14px;
      display:block;
    }

  }

}
#forgetpass-page,
#login-page {
  .btn {
    width: auto;
  }
}
