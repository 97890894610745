.offcanvas-cart-item-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &:last-child{margin-bottom: 0;}
}

.offcanvas-cart-item-block{
    display: flex;
    align-items: center;
}

.offcanvas-cart-item-image-link {
    overflow: hidden;
    border: 1px solid $borderColor;
    margin-right: 20px;
    width:auto;
    flex: 0 0 auto;
}

.offcanvas-cart-image {
    width: 90px;
    height: 90px;
}

.offcanvas-cart-item-link {
    display: block;
    font-weight: 700;
}

.offcanvas-cart-item-details{
    font-family: $secondery-font;
}

.offcanvas-cart-total-price {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-family: $secondery-font;
}

.offcanvas-cart-total-price-text {
    font-weight: 700;
}

.offcanvas-cart-action-button-list {
    margin-bottom: 25px;
    display: block;
}

.offcanvas-cart-action-button-link {
    display: block;
    background: $black;
    color: $white;
    padding: 10px 30px;
    text-align: center;
    font-weight: 600;

    &:hover{
        background: $themeColor;
        color: $white;
    }
}