.product-list-single {
    display: flex;
    padding: 30px;
    flex-direction: column;
    @include breakpoint(medium){
        flex-direction: row;
    }
}

.product-list-link a{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
    color: $headingColor;
    &:hover{color: $themeColor;}
}

.product-list-img-link {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
    @include breakpoint(medium){
        width: 35%;
        margin-right: 30px;
    }
    @include breakpoint(xlarge){
        width: 25%;
        margin-right: 30px;
    }
}

.product-list-content {
    width: 100%;
    @include breakpoint(medium){
        width: 65%;
    }
    @include breakpoint(xlarge){
        width: 75%;
    }
}

.product-list-price {
    display: block;
    font-size: 20px;
    color: $themeColor;
    font-weight: 500;
    margin-bottom: 15px;
}

.product-list-price-off {
    color: $bodyColor;
    font-size: 18px;
}

.product-action-icon-link-list{
    display: block;
    li {
        margin-right: 10px;
        display: inline-block;
        transition: $baseTransition;
    }

    li a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        line-height: 39px;
        text-align: center;
        border: 2px solid $borderColor;
        display: block;
        background: $white;
    }

    li a:hover{
        background: $themeColor;
        border-color: $themeColor;
        color: $white;
    }
}
