.tag-link {
    margin-bottom: -10px;
    a {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid $borderColor;
        margin-bottom: 10px;
        margin-left: 5px;
        border-radius: 3px;
        &:hover{
            background: $themeColor;
            border-color: $themeColor;
            color: $white;
        }
    }
}