/*****************************
Pages - 404 Page
*****************************/

.error_form {
    text-align: center;
    h1{
        font-weight: 600;
        color: $themeColor;
        font-size: 130px;
        line-height: 120px;
        letter-spacing: 4px;
        margin: 0 0 30px;
        @include breakpoint(medium){
            font-size: 200px;
            letter-spacing: 10px;
            line-height: 1;
            margin: 0 0 18px;
        }
    }

    h4{
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 17px;

        @include breakpoint(medium){
            line-height: 30px;
            margin-bottom: 19px;
            font-size: 24px;
        }
    }

    p {
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 30px;
    }

    a {
        color: $white;
        display: inline-block;
        background: $bodyColor;
        font-size: 12px;
        font-weight: bold;
        height: 40px;
        line-height: 42px;
        padding: 0 30px;
        text-transform: uppercase;
        margin-top: 35px;
        border-radius: 3px;

        &:hover{
            background: $themeColor;
        }
    }
}