/*Kategorija proizvoda */

#shop-category-header {

    .title {
        font-size: 1.6em;
        font-weight: 400;
    }

    .title-bottom-line {
        padding-bottom: 10px;
        position: relative;
        display: block;
        border-bottom: 1px solid #dadada;
    }
    .title-bottom-line::after {
        content: ' ';
        border-bottom: 2px solid $primary;
        display: block;
        position: absolute;
        bottom: -1px;
        width: 50%;
    }

    img{
        margin-right:10px;
    }
}

#shop-category-sub {


}

/* Lista proizvoda */
#product-sort {
    margin-bottom: 20px;
    .label-count {
        font-size: 0.9rem;
        line-height: 35px;
        margin:0;
        text-align: right;
    }
    select {
        margin-right: 20px;
        height: 35px;
    }    
}

#product-list  {

    //padding:0 20px;

    .product-card {
        margin: 0 0 20px 0 !important;
  }
}

#product-list .card-group {
  padding-left: 10px;  
  padding-right: 10px;  
}

#shop-filters {
    .card-header {
        justify-content: space-between;
        display: flex;
    }
}

/* Box kategorije */
.category-box-card  {

    margin-bottom:1rem;

    .card-title {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .card-title a{
        color:$gray-900;
    }
    .card-img::before {
      bottom: 0px;
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
    }

}
.category-box-card:hover  {
    .card-img::before {

      background: rgba(0, 0, 0, 0);
    }
}

.product-category-card {
    background-color: #fff;
    /*height: 160px;*/
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid $gray-100;
    padding:4px;
    opacity: 1;
    margin-top: 15px;

    .card-img-container {

    }

    .card-img-container-bg {
        height: 150px;
        background-size: 180px;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-transition: all .15s linear;
        -moz-transition: all .15s linear;
        -ms-transition: all .15s linear;
        -o-transition: all .15s linear;
        transition: all .15s linear;
        filter: grayscale(100%);
    }
    .card-title {
        font-size:1rem;
        text-align: center;
        font-weight: 400;
        margin-top:5px;
        a {
            color:$black;
        }
    }
}

.product-category-card:hover {
    opacity: 1;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.3);

    .card-img-container-bg {
        filter: none;
    }
}

.sidebar-menu {

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  & > ul {
    & > .nav-item{
      border-bottom:1px solid $themeColor;
      padding: 0 10px;
    }
  }

  .nav-item{
    position: relative;
  }


  .nav-link{
    margin-right:30px;
    font-size:1rem;
    padding: 10px 0 7px 0;
    color:#373737;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
  }

  li.active > .nav-link,
  .nav-link:hover{
    color:$themeColor;
  }

  .nav-sub-toggle  {
    float:right;
    cursor: pointer;
    line-height: inherit;
    position: absolute;
    right:10px;
    top: 10px;
  }

  .submenu  {
    margin-left:20px;
    //border-left:1px solid #e9e7e7;
    margin-bottom: 5px;
  }

  .nav-sub-toggle{
    width: 60px;
    height: 35px;

  }
  .nav-sub-toggle:not(.collapsed) {
    color: #1F2221;
    background-color: transparent;
    box-shadow: none;
  }
  .nav-sub-toggle i::before {
    display: block;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }
  .nav-sub-toggle.collapsed i::before {

  }
  .nav-sub-toggle:not(.collapsed) i::before {
    //background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

}

.sidebar-menu {
    overflow: auto;

    height: 100%;
    & > ul {
        display: flex;
        flex-direction: column;

        margin: 0;
        padding: 0;

        list-style: none;
        & > li {
            position: relative;
            & + li {
                border-top: 1px solid #EEEEEE;
            }
            & > a {
                display: block;

                padding: 11px 40px 11px 0;

                text-transform: uppercase;
            }
        }
    }
}

/* Sekcija proizvoda */

.products-section {


}


.product-categories-section {

    background-color: #f5f5f5;
}


@include media-breakpoint-down(sm)  {
  #product-sort {
    margin-top:20px;

}

}

@include media-breakpoint-down(xs)  {

  #product-sort {
    select {
        margin: 0 !important;
    }
}

}