.mobile-header-wrapper{
    padding-top: 25px;
    padding-bottom: 25px;
}
.mobile-menu{
    border: 1px solid $black;
    padding: 5px;
    display: block;
}

.mobile-menu-dash{
    display: block;
    width: 30px;
    height: 2px;
    background: $black;
    margin-top: 5px;
    margin-bottom: 5px;
}