/*****************************
Pages - Cart
*****************************/
.cart_submit {
    text-align: right;
    padding: 12px;

    button{
        background: #333;
        border: 0;
        color: $white;
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        height: 38px;
        line-height: 18px;
        padding: 10px 15px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 3px;

        &:hover{
            background: $themeColor;
        }
    }
}


.coupon_code {
    border: 1px solid #ededed;
   margin-top: 60px;
    h3 {
        color: #ffffff;
        line-height: 36px;
        padding: 5px 15px;
        background: #333;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }
}

.coupon_inner {
    padding: 10px 20px 25px;
    p {
        font-size: 13px;
        margin-bottom: 20px;
    }
    input {
        border: 1px solid #ededed;
        height: 42px;
        background: none;
        padding: 0 20px;
        margin-right: 20px;
        font-size: 12px;
        color: #333;
        width: 175px;
    }

    button {
        background: #333;
        border: 0;
        color: $white;
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        height: 38px;
        line-height: 18px;
        padding: 10px 15px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 3px;

        &:hover{
            background: $themeColor;
        }
    }

    a {
        display: block;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
        border-bottom: 1px solid #ededed;
        padding-bottom: 10px;
        border-radius: 3px;
    }
}


.cart_subtotal {
    display: flex;
    justify-content: space-between;
    p {
        font-weight: 600;
        font-size: 14px;

        span {
            margin-right: 30px;
        }
    }

    .cart_amount {
        font-size: 18px;
        font-weight: 500;
    }
}

.checkout_btn {
    text-align: right;
    a{
        background: $themeColor;
        font-size: 15px;
        padding: 3px 14px;
        line-height: 30px;
        font-weight: 500;
        display: inline-block;
        text-transform: capitalize;
        margin-bottom: 0;
        color: $white;

        &:hover{
            background: #333;
        }
    }
}