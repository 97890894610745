/*****************************
Components - Tab Style
*****************************/

/* Tab Style CSS */
.tab-content .tab-pane {
    display: block;
    height: 0;
    max-width: 100%;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
}

.tab-content .tab-pane.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

.sort-tab-btn,
.product-tab-btn,
.product-details-content-tab-btn{
    margin-top: 30px;
    @include breakpoint(medium){margin-top: 0;}

    & > li{
        margin-right: 40px;
        &:last-child{margin-right: 0;}

        & > .nav-link{
            color: $bodyColor !important;
            transition: $baseTransition;
            font-weight: 500;
            padding: 0;
            margin-top: 10px;
            @include breakpoint(medium){margin-top: 0;}
        }

        & > .nav-link:hover,
        & > .nav-link.active{
            color: $themeColor !important;
            transition: $baseTransition;
            img{
                transition: $baseTransition;
                filter:  invert(100%);
            }
        }
    }
}

.product-details-content-tab-btn{
    margin-top: 0;
    & > li{
        margin-right: 0;
        a{margin-right: 40px;}


        & > .nav-link h5{
            color: $headingColor !important;
            transition: $baseTransition;
            font-weight: 600;
            padding: 0;
            margin-top: 10px;
            @include breakpoint(medium){margin-top: 0;}
        }

        & > .nav-link:hover h5,
        & > .nav-link.active h5{
            color: $themeColor !important;
            transition: $baseTransition;
            img{
                transition: $baseTransition;
                filter:  invert(100%);
            }
        }
    }
}

.sort-tab-btn{
    & > li{
        margin-right: 20px;
        &:last-child{margin-right: 0;}
    }
}

.product-tab-btn{
    margin-top: 35px;
    margin-bottom: -20px;
    font-size: 16px;
    & > li > .nav-link{
        margin-top: 0;
    }
    @include breakpoint(medium){
        margin-top: 0;
        margin-bottom: 0;
    }
}
.sort-tab-btn{
    margin-top: 0px;
    & > li > .nav-link{
        margin-top: 0;
    }
}


.sort-box > *{
  //margin-right: 20px;
    margin-bottom: 20px;
    &:last-child{
      margin-right: 0;
    }
    @include breakpoint(medium){
        margin-bottom: 0;
    }
}
