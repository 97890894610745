/*****************************
Pages - Contact Us
*****************************/

.gmap_canvas {
    iframe{
        overflow:hidden;
        background:none!important;
        height:500px;
        width: 100%;
    }
    
}

.contact-details-single-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child{margin-bottom: 0;}
}

.contact-details-wrapper {
    background: $gray-100;
    padding: 40px 30px;
}

.contact-details-icon {
    font-size: 22px;
    margin-right: 20px;
    color: $white;
    background: $themeColor;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

.contact-details-content {
    a, span {
        display: block;
        font-size: 16px;
    }
}


.contact-social {
    margin-top: 22px;
    h4 {
        margin-bottom: 10px;
    }

    li {
        display: inline-block;
        margin-right: 17px;
    }

    li a {
        font-size: 18px;
        display: block;
    }
    
}


.contact-form{
    background: $gray-100;
    padding: 40px 30px;

    h3{
        margin-bottom: 20px;
    }
}

.contact-submit-btn{
    border: none;
    background-color: $themeColor;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    padding: 15px 52px;
    outline: 0;
    transition: all .3s ease 0s;
    border-radius: 5px;

    &:hover{
        background: #333;
    }
}