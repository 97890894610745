#modalQuickview{
  .product-image-large-single {
    img{
      margin: 20px auto;
      @include  breakpoint(medium){
          margin: 0px auto;
      };
    }
  }
}

.modal-product-details-social {
    display: flex;
    flex-wrap: wrap;
    li {
        margin-right: 10px;
    }
    li a {
        width: 30px;
        height: 30px;
        display: block;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
    }
}


.product-image-thumb{
    position: relative;
    img{
        border: 1px solid $borderColor;
    }

    &.modal-product-image-thumb{
        margin-top: 15px;
        .slick-list{
            margin-left: -7.5px;
            margin-right: -7.5px;
        }
        .slick-slide{
            margin-left: 7.5px;
            margin-right: 7.5px;
        }
    }

    &:hover .gallery-nav{
        visibility: visible;
        opacity: 1;
    }

}
