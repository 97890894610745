
/* User profile / checkout navigacija  */
#shop-navigation  {

    .card-nav-shop {
        li {
            padding:10px 20px;
        }
        li.active {
            a {
                color:#fff;
            }
        }
        a {
            font-size: 0.925rem;
            padding:5px 0;
            font-weight: 500;
            color:#000;
        }
    }
    .heading {
        font-size: 16px;
        text-transform: uppercase;
        color: #666;
        font-weight: 600;
        border-bottom: solid 1px #e4e4e4;
        margin: 0 0 25px;
        padding-bottom: 10px;
    }    

    
/*
    .stepNumber1 {
        display: block;
        border-radius: 50%;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background: $secondary;
        color: #fff;
        text-align: center;
        float: left;
        padding: 5px;
    }

    li.active .stepNumber1 {
        background: #fff;
        color: $secondary;
    }
    li.active, 
    li a.active {
        color: #fff !important; 
    }

*/
}

