#checkout-page {
    section {
        padding: 0;
    }

    .card-order-box {
        background: #F9F9F9;
        color: #2D2D2D;
        padding: 20px 4px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;

        span.name {
            font-size: 16px;
            margin-top: 0;
            margin-bottom: 10px;
            display: block;
        }

        span.price {
            margin: 10px 0;
            display: block;
        }

        :hover {
            cursor: pointer;
        }

        i {
            color: $blue;
            font-size: 40px;
            display: block;
            margin-bottom: 15px;
        }

    }
    #checkout-process-form {

    }


}


/* Checout success page */

.checkout-success-section {
    padding: 20px 0;
    color:#fff;

    .title{
        color:#fff;
    }

    i {
        font-size:3rem;
        margin-top:1rem;
    }
}


#checkout-success-page {

    .heading {
        margin-bottom: 1.5rem;
    }

    .card-payment {
        margin-bottom: 30px;

        .bank-details .company {
            font-size: 1.2rem;
        }
        .bank-details p {
            margin-bottom:10px;
        }
    }

    .btn-continue {

    }

}

#payment-method-form {
    overflow: hidden;
}
@media (max-width: 991.98px) {
    #checkout-success-page {
        margin-top:30px;
    }
}

@media (max-width: 767.98px) {
    #checkout-page {
        #checkout-process-form {
            margin-top:20px;
            
        }
    }
}

@media (max-width: 575.98px) {

}