/*****************************
Layout - Footer
*****************************/

/*Footer Top Area*/
.footer-logo{
    margin-bottom: 25px;
}

.footer-contact{
    font-size: 14px;
    line-height: 25px;
}

.customer-support{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: none;

    @include breakpoint(large){
        display: flex;
    }
}

.customer-support-icon{
    min-width: 32px;
    margin-right: 15px;

    @include breakpoint(xlarge){}
}

.customer-support-text-phone{
    display: block;
    font-size: 17px;
    line-height: 27px;
    font-weight: 600;
    color: $bodyColor;
    font-family: $secondery-font;
    @include breakpoint(xlarge){
        font-size: 24px;
    }
    &:hover{
        color: $themeColor;
    }
    
}

.footer-social{
    margin-top: 14px;
    margin-bottom: 50px;
    @include breakpoint(medium){margin-bottom: 0;}
    & > li{
        display: inline-block;
        margin-right: 13px;
        &:last-child{margin-right: 0px;}
    }

    & > li > a{
        display: inline-block;
        font-size: 16px;
        padding: 0;
        line-height: 40px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        text-align: center;
        color: $white !important;

        &:hover{opacity: .7;}

        &.facebook{background: $facebook;}
        &.twitter{background: $twitter;}
        &.youtube{background: $youtube;}
        &.pinterest{background: $pinterest;}
        &.instagram{background: $instagram;}
    }
}

.footer-widget-title{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    text-transform: capitalize;
    font-weight: 600;
}

.footer-widget-subscribe{
    margin-top: 30px;
    @include breakpoint(small){
        margin-top: 0;
    }
}

.footer-widget-subscribe-note{
    font-size: 14px;
    line-height: 25px;
    margin-top: 20px;
}

.footer-widget-menu{
    padding-left: 0px;

    @include breakpoint(large){
        padding-left: 65px;
    }
}


.footer-menu{
    display: flex;
    justify-content: space-between;
}

.footer-menu-nav{
    & > li{
        line-height: 30px;
    }

    & > li > a{
        display: block;
        font-weight: 400;
        font-size: 14px;
        color: $bodyColor;
        &:hover{
            color: $themeColor;
            transform: translateX(10px);
        }
    }
}

.footer-bottom{
    padding: 15px 0;
}

.copyright-area{
    text-transform: capitalize;
    font-size: 14px;
    text-align: center;

    @include breakpoint(medium){
        text-align: left;
    }
}

.footer-payment{
    text-align: center;
    @include breakpoint(medium){
        text-align: right;
    }
}

.copyright-area-text{
    color: $bodyColor;
    margin-bottom: 10px;
    @include breakpoint(medium){
        margin-bottom: 0;
    }
}

.copyright-link{
    font-weight: 600;
    color: $themeColor;
    &:hover{
        color: $themeColor;
    }
}