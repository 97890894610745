/*Product Catagory CSS*/
.product-catagory-wrapper{
    margin-bottom: 0px;
    
    @include breakpoint(small){ margin-bottom: -30px;}
    @include breakpoint(medium){ margin-bottom: -30px;}
    @include breakpoint(large){ margin-bottom: -30px;}
}

.product-catagory-wrapper > .container > .row > .col-12{
    margin-bottom: 30px;
    &:last-child{margin-bottom: 0;}

    @include breakpoint(small){
        margin-bottom: 30px;
    }
} 

.product-catagory-single {
    display: flex;
    align-items: center;

    @include breakpoint(large){
        height: 125px;
    }

    width: 100%;
    padding: 10px;
    border: 2px solid $borderColor;
    transition: $baseTransition;

    &:hover{
        border: 2px solid $themeColor;
        .product-catagory-title {
            color: $themeColor;

        }
    }
}

.product-catagory-img {
    display: block;
    width: 40%;
    overflow: hidden;
    margin-right: 5%;
    img{
        width: 100%;
        height: auto;
    }
}

.product-catagory-content {
    width: 55%;
}


.product-catagory-title {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    transition: $baseTransition;
}

.product-catagory-items {
    font-size: 14px;
    color: $black;
}


