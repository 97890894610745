
    .modal-add-cart-product-img{
        text-align: center;
        img{
          margin: 20px auto;
          @include  breakpoint(medium){
              margin: 0px auto;
          };
        }
    }
    .modal-add-cart-product-title{
        margin-top: 25px;
        @include breakpoint(medium){
            margin-top: 0px;
        }
    }
    .modal-add-cart-product-title,
    .modal-add-cart-product-price{
        display: block;
        font-weight: 500
    }

    .modal-add-cart-product-info,
    .modal-add-cart-product-shipping-info {
        font-weight: 500;
        li{
            margin-bottom: 10px;
            &:last-child{margin-bottom: 0;}
        }
        span{
            font-weight: 300;
            padding-left: 5px;
        }
    }

    .modal-add-cart-product-cart-buttons{
        display: flex;
        flex-direction: column;
        @include breakpoint(small){ flex-direction: row;}
        a{
            margin-top: 10px;
            margin-bottom: 10px;
            background: red;
            color: white;
            padding: 5px 10px;
            margin-right: 0px;
            &:last-child{margin-right: 0;}
            @include breakpoint(small){
                margin-top: 20px;
                margin-bottom: 15px;
                margin-right: 10px;
                flex-direction: row;
            }
        }

    }


.modal-add-cart-info i {
    color: red;
    padding-right: 10px;
    font-size: 16px;
}


.modal-continue-button{
    a{
        text-decoration: underline;
        color: $themeColor;

        &:hover{
            text-decoration: none;
        }
    }
}
