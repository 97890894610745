.recent-post-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    border-bottom: 1px solid $borderColor;

    &:first-child{
        margin-top: 0;
    }
    &:last-child{
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

.post-image {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    overflow: hidden;
    img {
        width: 100%;
    }
}

.post-link {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.post-date {
    font-size: 13px;
}