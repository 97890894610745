
/*****************************
Pages - About Us
*****************************/

/* About Us Top Area */
.about-us-top-content{
    margin-bottom: -12px;
    h4{
        margin-top: 27px;
        margin-bottom: 15px;
    }
    p{
        padding: 0 0;
        line-height: 26px;
        margin-bottom: 0;
        @include breakpoint(medium){padding: 0 2%;}
        @include breakpoint(large){padding: 0 8%;}
        @include breakpoint(xlarge){padding: 0 12%;}
    }
}

/* About Us Center Area */
.about-us-center-area,
.about-us-bottom-area{
    margin-bottom: -37px;
}
.about-us-center-content{
    h4{
        margin-bottom: 15px;
    }
}

.about-promo-single-item{
    text-align: center;
    margin-bottom: 30px;

    img {
        width: 90px;
        height: 90px;
    }

    h6 {
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    
}

.about-feature-single-item {
    text-align: center;
    margin-bottom: 30px;

    h6 {
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    
}

/* About Us Bottom Area */